/** @jsx jsx */
import { jsx, Button, Spinner, Message } from "theme-ui";
import PropTypes from "prop-types";
import { useCallback } from "react";
import SrcmForm from "gatsby-plugin-hfn-profile/components/SrcmForm";
import { setUser, showAlert } from "gatsby-plugin-hfn-profile/state/actions";
import { useDispatch } from "react-redux";
import { fget } from "gatsby-plugin-hfn-profile/components/Login";
import formDefinition from "./formDefinition";
import reloadPage from "./reloadPage";

const one = "one";

//
// DeepLinkWidget passes srcmProfile, meId, onSignIn and raiseAlert
//

function ProfileWidgetSubscriptions({ authToken, meId }) {
  const dispatch = useDispatch();
  const raiseAlert = useCallback(
    (e) => {
      dispatch(showAlert({ message: e }));
    },
    [dispatch]
  );
  const onSignIn = useCallback(
    (newsrcmprofile) => {
      if (!authToken) {
        dispatch(
          setUser(
            fget(
              newsrcmprofile,
              newsrcmprofile.user_firebase_uid
                ? newsrcmprofile.user_firebase_uid
                : newsrcmprofile.user_email
            )
          )
        );
      }
    },
    [dispatch, authToken]
  );

  const srcmApiForForm = SrcmForm.useSrcmApiForForm(
    { api: `/api/v2/me/${meId}/`, authToken },
    { method: "GET", onSuccess: onSignIn, onFailure: raiseAlert },
    formDefinition,
    one
  );

  const { apiInProgress, apiError, formData } = srcmApiForForm;

  return (
    <div key={formData ? formData.ref : "A"}>
      <SrcmForm
        srcmApiForForm={srcmApiForForm}
        callApiMethod="PATCH"
        callApiSaveCallback={onSignIn}
      >
        {/* <div>
             <div sx={{ textAlign: "center", my: 2 }}>
               <Text variant="header">My Heartfulness Subscriptions</Text>
             </div>
           </div> */}

        {(apiInProgress || !formData.id || apiError) && (
          <div>
            {(apiInProgress || formData.id) && <Spinner />}
            {!apiInProgress && apiError && <Message>{apiError}</Message>}
            {!apiInProgress && (
              <div sx={{ mt: 2, width: "200px" }}>
                <Button variant="variant3" onClick={reloadPage}>
                  Refresh Page
                </Button>
              </div>
            )}
          </div>
        )}

        {!!formData.id && (
          <div sx={{ textAlign: "left" }}>
            <div>
              <SrcmForm.SubForm title="Communication Preferences" name={one}>
                <SrcmForm.InputField name="optout_email" section={one} />
              </SrcmForm.SubForm>
            </div>
          </div>
        )}
      </SrcmForm>
    </div>
  );
}
ProfileWidgetSubscriptions.propTypes = {
  meId: PropTypes.number.isRequired,
  authToken: PropTypes.string,
};
ProfileWidgetSubscriptions.defaultProps = {
  authToken: undefined,
};

export default ProfileWidgetSubscriptions;
