import { formatSys2OdooDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import jsonTransformer from "sites-common/utils/jsonTransformer";
import { compact } from "sites-common/utils/lodash";
import getbit from "sites-common/utils/getbit";

const today = new Date();

const transformApiDataOnFetching = (r) => {
  if ("communication_preference" in r) {
    const rcp = r.communication_preference;
    const r1 = { ...r };
    r1.communication_preference_1 = compact([
      getbit(rcp, 1) && "E",
      getbit(rcp, 2) && "S",
      getbit(rcp, 4) && "W",
    ]);
    return r1;
  }
  return r;
};

const transformFormDataForSaving = (r1) => {
  const r = { ...r1 };
  if ("age" in r) {
    r.age = parseInt(r.age, 10);
    const d = new Date();
    d.setFullYear(today.getFullYear() - r.age);
    r.date_of_birth = formatSys2OdooDate(d);
  }
  if ("communication_preference_1" in r) {
    const rcp = r.communication_preference_1;
    r.communication_preference =
      (rcp.includes("E") && 1) +
      (rcp.includes("S") && 2) +
      (rcp.includes("W") && 4);
  }
  return jsonTransformer(
    [
      { t: "rel2id", i: ["city_id"], o: ["city_id"] },
      { t: "rel2id", i: ["citizen_of_country"], o: ["citizen_of_country"] },
      { t: "rel2id", i: ["srcm_group"], o: ["srcm_group"] },
      { t: "rel2id", i: ["indiv_sittings_by"], o: ["indiv_sittings_by"] },
      // { t: "relArr2idArr", i: ["organizers"], o: ["organizers"] },
      // { t: "omit", i: ["zone"] },
      // { t: "copy", i: ["is_paid_event"], o: ["is_paid_event"] },
    ],
    JSON.parse(JSON.stringify(r))
  );
};

const fieldDefinitionsDict = {
  email: { type: "email", label: "Email ID", icon: "envelope" },
  mobile: {
    type: "phone",
    label: "Mobile",
    icon: "mobile",
    enableSearch: true,
    countryCodeEditable: false,
    country: "in",
  },
  first_name: { type: "text", label: "First Name" },
  name: { type: "text", label: "Display Name", required: false },
  last_name: { type: "text", label: "Last Name" },
  middle_name: { type: "text", label: "Middle Name", required: false },
  gender: { type: "gender", label: "Gender" },
  city_id: {
    label: "City",
    type: "city_idname",
    options: {
      "srcm_group.id": "c_id",
      "srcm_group.name": "c_name",
      /* city: "name", state: "state", country: "country" */
    },
  },
  citizen_of_country: {
    label: "Nationality / Citizenship",
    type: "country_idname",
  },
  srcm_group: {
    label: "Nearest Heartfulness Center",
    type: "srcmgroup_idname",
    helperText:
      "where you attend sunday group meditations; enter country name if your center is not visible in dropdown",
  },
  date_of_birth: { type: "date", label: "Date of Birth", required: false },

  age: {
    type: "number",
    label: "Age",
    min: 15,
    max: 110,
    validator: (v) => {
      if (v < 15) {
        return "Age should be minimum 15";
      }
      if (v > 110) {
        return "Enter a valid age";
      }
      return false;
    },
  },
  date_of_joining: {
    type: "date",
    label: "Date of starting Heartfulness Practice",
    maxDate: formatSys2OdooDate(today),
  },
  profession: { type: "text", label: "Current Profession", required: false },
  indiv_sittings_by: {
    type: "preceptor_idname",
    label: "Current Preceptor",
    helperText: "Trainer with whom you take individual sittings",
  },
  photo: { type: "photo", label: "Photo", required: false, hideLabel: true },
  street: { label: "Address (line 1)" },
  street2: { label: "Address (line 2)" },
  street3: { label: "Address (line 3)", required: false },
  street4: { label: "Address (line 4)", required: false },
  postal_code: { label: "Postal Code" },
  searchable: {
    required: false,
    type: "toggle",
    label:
      "I consent to my family members / friends / volunteers viewing my Name and City while registering me for Heartfulness events using my Heartfulness ID / email / phone number",
  },
  optout_email: {
    label:
      "Opt-out from the mailing list to stop receiving updates or promotional news from Heartfulness",
    type: "toggle",
    required: false,
    offText: "No, please keep me in the mailist list",
    onText: "Yes, please opt-out",
  },
  communication_preference: {
    required: false,
    type: "number",
    label: "Communication Preference Hidden",
  },
  communication_preference_1: {
    type: "multicheckbox",
    options: [
      { name: "S", label: "SMS" },
      { name: "W", label: "WhatsApp" },
      { name: "E", label: "Email" },
    ],
    label: "Communication Preference",

    btnSelectAll: false,
    btnSelectNone: false,
  },
};

const formDefinition = {
  fields: fieldDefinitionsDict,
  validator: (changed, formData) => {
    try {
      if (formData.age && formData.date_of_joining) {
        const t1 = new Date().getFullYear() - formData.age;
        const t2 = parseInt(formData.date_of_joining.substr(0, 4), 10);
        if (t1 + 11 > t2) {
          if ("age" in changed) {
            return {
              age: "Invalid age or date of starting Heartfulness Practice",
            };
          }
          return {
            date_of_joining: "Invalid date of starting Heartfulness Practice",
          };
        }
      }
    } catch (e) {
      // console.error(e);
    }
    return false;
  },
  transformFormDataForSaving,
  transformApiDataOnFetching,
  errorTextMissingFields: "Missing Fields",
  errorTextInvalidFields: "Invalid Fields",
};

export default formDefinition;
