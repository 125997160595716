/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import DLWLayout from "../../layouts/dlw-mobile";
import ProfileWidgetSubscriptions from "../../components/ProfileWidgetCore/ProfileWidgetSubscriptions";

function ProfilePage() {
  const { srcmProfile } = useAuth();
  const meId = srcmProfile?.id;
  return (
    <DLWLayout>
      <div sx={{ textAlign: "center" }}>
        <div sx={{ mb: 2 }}>
          <Text variant="header"> Subscriptions </Text>
        </div>
      </div>

      <ProfileWidgetSubscriptions meId={meId} />
    </DLWLayout>
  );
}

export default ProfilePage;
